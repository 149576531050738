<template>
  <div class="asset-info">
    <div class="leftBack" @click="goBack">
      <van-icon name="arrow-left" />
    </div>
    <div class="asset-content">
      <h3 class="asset-list">资产盘点单信息</h3>
      <div class="asset-name">
        <van-row gutter="5" v-for="item in contentInfo" :key="item.key">
          <van-col span="8" class="sub-title">{{ item.title }}</van-col>
          <van-col span="16" class="sub-content"
            ><span>{{ item.content || "-" }}</span></van-col
          >
        </van-row>
      </div>
      <div class="upload-img-content">
        <h3 class="asset-list">{{ type == 1 ? "上传" : "查看" }}照片</h3>
        <div class="upload-wrapper">
          <van-uploader
            v-model="imgTempList"
            :after-read="afterRead"
            result-type="dataUrl"
            :max-count="1"
            :capture="capture"
            accept="image/*"
            :disabled="type != 1"
          />
        </div>
      </div>
      <van-button
        v-if="type == 1"
        class="btn"
        @click="saveAsset"
        :loading="saveLoading"
        loading-text="确认中..."
      >
        确 认
      </van-button>
      <van-overlay :show="uploading" :lock-scroll="false" z-index="99999">
        <div class="wrapper" @click.stop>
          <div class="block">
            <van-loading type="spinner" size="6.4vw" color="#1989fa" />
            <span style="margin-left: 2.13333vw;">图片上传中...</span>
          </div>
        </div>
      </van-overlay>
      <footer></footer>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant/lib/";
export default {
  name: "receiveCheckInfo",

  data() {
    return {
      capture: ["camera"],
      current: 1,
      video: "",
      videoElement: "",
      type: "",
      info: JSON.parse(this.$route.query.item) || {},
      contentInfo: [],
      imgTempList: [],
      imgList: [],
      saveLoading: false,
      uploading: false,
    };
  },
  activated() {
    this.info = JSON.parse(this.$route.query.item);
    this.type = this.$route.query.type;
    this.imgTempList = [];
    this.contentInfo = [
      {
        key: "1",
        title: "名称：",
        content: this.info["propertyBasicInfo.name"],
      },
      {
        key: "2",
        title: "编码：",
        content: this.info["propertyBasicInfo.code"],
      },
      {
        key: "3",
        title: "类型：",
        content: this.info["propertyBasicInfo.belongTypeDic.name"],
      },
      {
        key: "4",
        title: "规格型号：",
        content: this.info["propertyBasicInfo.specificationInfo"],
      },
      {
        key: "5",
        title: "车牌号：",
        content: this.info["propertyBasicInfo.principal"],
      },
      {
        key: "6",
        title: "设备使用人：",
        content: this.info["propertyBasicInfo.unitPeople"],
      },
      {
        key: "7",
        title: "开始使用日期：",
        content: this.info["startTime"],
      },
    ];
    if (this.type == 2) {
      this.getImg();
    }
  },
  created() {
    // console.log(JSON.parse(this.$route.query.item), "query");
    // this.info = JSON.parse(this.$route.query.item);
    // console.log(this.info);
    // this.contentInfo = [
    //   {
    //     key: "1",
    //     title: "名称：",
    //     content: this.info["propertyBasicInfo.name"],
    //   },
    //   {
    //     key: "2",
    //     title: "编码：",
    //     content: this.info["propertyBasicInfo.code"],
    //   },
    //   {
    //     key: "3",
    //     title: "类型：",
    //     content: this.info["propertyBasicInfo.belongTypeDic.name"],
    //   },
    //   {
    //     key: "4",
    //     title: "规格型号：",
    //     content: this.info["propertyBasicInfo.specificationInfo"],
    //   },
    //   {
    //     key: "5",
    //     title: "车牌号：",
    //     content: this.info["propertyBasicInfo.principal"],
    //   },
    // ];
  },
  watch: {},
  mounted() {
    this.type = this.$route.query.type;
    console.log(this.type, "mounted");
  },
  onLoad(options) {
    console.log(options, "onLoad");
  },

  methods: {
    async saveAsset() {
      // this.imgList[0].fileName = this.imgList[0].filename;
      // this.imgList[0].fileID = this.imgList[0].id;
      // this.imgList[0].dicTypeName = "";
      // this.imgList[0].fileCode = "";
      // this.imgList[0].createTime = "";
      // delete this.imgList[0].id;
      // delete this.imgList[0].contentType;
      // delete this.imgList[0].name;
      // delete this.imgList[0].filename;

      if (!this.imgList[0].filename || !this.imgList[0].id) {
        Toast.fail("未获取到图片信息");
        return false;
      }

      const imgList = [
        {
          fileName: this.imgList[0].filename,
          fileID: this.imgList[0].id,
          dicTypeName: "",
          fileCode: "",
          createTime: "",
        },
      ];
      const params = {
        id: this.info.id,
        attachinfo: imgList,
      };

      const option = {
        portName: "cms.zlcheck.detail.updateEntity",
        data: params,
        successCallback: (data) => {
          this.imgList.length = 0;
          this.goBack();
        },
      };
      this.saveLoading = true;
      await this.$sapi.callPort(option);
      this.saveLoading = false;
    },
    afterRead(data) {
      this.uploading = true;
      this.$sapi.uploadFilePro(data.file).then((res) => {
        this.uploading = false;
        this.imgList = res.data;
      });
    },
    async getImg() {
      const that = this;
      const params = {
        id: this.info.id,
      };
      const option = {
        portName: "cms.property.zlbasicInfo.getEntity",
        data: params,
        successCallback(data) {
          that.imgTempList.length = 0;
          that.imgTempList.push({
            url: data.data.url,
            isImage: true,
            deletable: false,
          });
        },
      };
      await this.$sapi.callPort(option);
    },
    handleAssetListA() {
      this.$router.push({
        path: "",
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    // 未盘点
    handleUncounted(index) {
      this.current = index;
    },
    // 已盘点
    handleOncounted(index) {
      this.current = index;
    },
  },
};
</script>

<style scoped>
.van-uploader__wrapper--disabled {
  opacity: 1;
}
.upload-wrapper {
  margin: 10px;
}
footer {
  height: 5rem;
}
.upload-img-content {
  margin-top: 2rem;
}
.asset-content {
  padding: 1rem;
  background-color: #fff;
}
.upload-img {
  width: 0;
  height: 0;
}
.asset-info {
  width: 100vw;
  height: calc(100vh - 88px);
  background: #ffffff;
  position: relative;
}

.leftBack {
  position: fixed;
  top: 11px;
  left: 10px;
  z-index: 99999;
}

.asset-name {
  /* background: #e6f7fe;
  border-radius: 1rem; */
  border-left: 1px solid #ccc;
  margin-left: 10px;
}
.asset-name .sub-title {
  text-align: right;
  font-size: 14px;
}
.asset-name .sub-content {
  font-weight: 600;
}
.asset-name > div {
  padding: 0.8rem 0;
  margin: 0;
  border-bottom: 1px solid #fff;
  /* margin: 0 1rem; */
  color: #333;
}

.asset-list {
  color: #666;
  margin: 15px 0;
  font-size: 18px;
  font-weight: 100;
  padding: 0 5px;
  border-left: 5px solid rgb(26 121 160);
  border-radius: 2px;
}

.btn {
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  width: 200px;
  text-align: center;
  background: linear-gradient(to right, rgb(125 182 206), rgb(26 121 160));
  border-radius: 20px;
  color: #ffffff;
  font-size: 14px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  background-color: #ffffffc2;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}
</style>
