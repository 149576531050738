<template>
  <van-list>
    <van-cell-group inset style="margin-top: 5.33333vw">
      <van-cell
        v-for="item in menuList"
        :key="item.title"
        :title="item.title"
        is-link
        @click="goTo(item.path)"
      >
      </van-cell>
    </van-cell-group>
  </van-list>
  <van-action-sheet
    round
    v-model:show="showBottom"
    :actions="wareHouseUserList"
    @select="onSelect"
  />
</template>

<script>
import { Dialog } from "vant";

export default {
  name: "Setting",
  data() {
    return {
      menuList: [
        { title: "切换仓库", path: "/warehouseSwitching" },
        { title: "更新程序", path: "/checkUpdate" },
        { title: "退出登录", path: "/logout" },
      ],
      showBottom: false,
      wareHouseUserList: [],
      curUser: {},
    };
  },
  created() {
    this.getCurUser();
  },
  methods: {
    onSelect(item) {
      this.changeWareHouse(item.id);
    },
    goTo: function (path) {
      if (_.isEmpty(path)) {
        return;
      }
      switch (path) {
        case "/logout":
          this.logout();
          break;
        case "/checkUpdate":
          location.reload();
          /* this.checkUpdate();*/
          break;
        case "/warehouseSwitching":
          this.getWareHouseUserList();
          this.showBottom = true;
          break;
        default:
          this.$router.push(path);
          break;
      }
    },
    logout: function () {
      this.$dialog
        .confirm({
          title: "退出登录",
          message: "是否确定退出登录",
        })
        .then(() => {
          // on confirm
          this.$sapi.clearUserInfo();
          this.$router.replace("/login");
        })
        .catch(() => {
          // on cancel
        });
    },
    getCurUser() {
      try {
        var option = {
          portName: "system.org.User.getCurUser",
          needLoading: false,
          successCallback: (data) => {
            this.curUser = data.entities[0];
          },
        };
        this.$sapi.callPort(option);
      } catch (error) {
        alert(error.message);
      }
    },
    changeWareHouse(id) {
      try {
        var option = {
          portName: "system.org.User.changeWareHouse",
          data: { "wareHouseUser.id": id },
          needLoading: false,
          successCallback: (data) => {
            location.reload();
          },
        };
        this.$sapi.callPort(option);
      } catch (error) {
        alert(error.message);
      }
    },
    getWareHouseUserList() {
      try {
        var option = {
          portName: "fr.warehouse.WareHouseUser.getWareHouseUserList",
          data: { pageIndex: 1, pageSize: -1 },
          needLoading: false,
          successCallback: (data) => {
            data.entities.forEach((item) => {
              if (item.id === this.curUser["wareHouseUser.id"]) {
                item.color = "rgb(36 208 78)";
              }
              item.name = item.wareHouseName;
            });
            this.wareHouseUserList = data.entities;
          },
        };
        this.$sapi.callPort(option);
      } catch (error) {
        alert(error.message);
      }
    },
    checkUpdate() {
      try {
        var option = {
          portName: "system.foundation.Dic.getDicDetail",
          data: { code: "andorid_apk" },
          needLoading: false,
          successCallback: (data) => {
            var entity = data.entities[0];
            Capacitor.Plugins.AppUpdate.checkUpdate({
              version: entity.value,
              downloadUrl: entity.dicAttach[0].url,
              desc: entity.des,
            });
          },
        };
        this.$sapi.callPort(option);
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>

<style scoped></style>
